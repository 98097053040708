

/* landing page css */


a{text-decoration: none !important;}
.navbar{background-color: #2C2B2B !important ;}
.navbar-nav{width: 80%;text-align: center;display: flex;justify-content: center;}
.navbar-nav .nav-link {color: #fff;margin: 0 10px;font-size: 16px;font-family: 'Roboto', sans-serif;}
.navbar-expand-lg .navbar-collapse{justify-content: space-between;}
/* .nav-link.active{color: #fff !important;border-bottom: 2px solid #96989B;padding-bottom: 0;} */
.active-custom:hover{color: red !important;border-bottom: 2px solid red;padding-bottom: 0;}
body{font-family: 'Inter', sans-serif;}
.nav-btns a{font-family: 'Epilogue', sans-serif;font-size:16px;border: 1px solid #fff;color: #fff;padding: 15px 20px;
border-radius: 5px;font-weight: 500;}
.nav-btns a:hover{background-color: #fff;color: #000;}
.login-btn{background-color: #96989B;border: 1px solid #96989B  !important;margin-left: 8px;padding: 20px 30px;}
.navbar-brand{width: 10%;}
.navbar-brand img{width: 180px;}
/*----------------------------------------------------------*/
.main-banner{background-image: url("./imgs/home-banner.jpg");background-position: center;background-repeat: no-repeat;background-size: cover;
height: 900px;color: #FFF;border-bottom: 4px solid #BBB8B8;}
.banner-info{text-align: center;padding-top: 10%;}
.banner-info h1{font-size: 72px;font-family: 'Inter', sans-serif;font-weight: 600;line-height: 120px;}
.banner-btn{font-size: 16px;padding: 15px 30px;border: 1px solid #fff;color: #fff;font-weight: 600;margin-top: 100px;
display:inline-block;border-radius:5px;font-family: 'Epilogue', sans-serif;font-weight: 500;}
.banner-btn:hover{background-color: #2C2B2B;color: #fff;}
.our-clients{background-color: #0e0e0e;position:relative;padding-bottom: 20px;}
.our-clients .row{position: relative;top: -70px;}
.inner-clnt-part{background-color: #fff;border-radius: 20px;color: #DB2C1D;padding: 40px 0;padding-left: 28%;}
.col:last-child .inner-clnt-part{padding-left: 18%;}
.inner-clnt-part h2{font-size: 72px;font-weight: 700;}
.inner-clnt-part p{font-size: 30px;font-weight: 500;}
.our-clients .col{margin-top: -60px;margin-left: 10px;margin-right: 10px;}
.our-clients .row{width: 95%;margin-left: auto;margin-right: auto;}
.our-clients .row .col-md-4{padding-left: 25px;padding-right: 25px;}
.our-clients .col-md-4.col-6:last-child .inner-clnt-part{padding-left: 17%;}
/*---------------------Our products------------------------*/
.our-products{color:#fff;background-image: url("./imgs/product-bg.jpg");background-repeat: no-repeat;background-size: cover;
    background-position: center;text-align: center;padding: 50px 0 100px 0;margin-top: -35px;}
.main-head{font-size: 40px;font-weight: 700;letter-spacing: 0.02em;position: relative;display: inline-block;padding-bottom: 20px;color: #312F2F;}
.main-head::before{content:'';position: absolute;left:0;width: 150px;bottom: 0;border-bottom: 3px solid #1D1C1C;}
.our-products .main-head::before{border-bottom: 3px solid #fff !important;}
.our-products img{width: 100%;}
.our-products  .col-md-3{margin-bottom: 20px;}
.our-products .row{margin-top: 50px;}
.infrastructre-info{font-family: 'Epilogue', sans-serif;}
.infrastructre{border-top: 4px solid #BBB8B8;border-bottom: 4px solid #BBB8B8;}
.infrastructre-info img{width: 100%;}
.infrastructre{padding: 70px 0;background-image: url("./imgs/infrastructure-bg.jpg");background-repeat: no-repeat;background-size: cover;
background-position: center;}
.infrastructre h2{margin-bottom: 100px;}
.infrastructre-info h3{font-size: 28px;font-weight: 700;}
.infrastructre-info p{font-size: 20px;text-align: justify;font-weight:400;line-height: 32px;width: 95%;
font-family: 'Epilogue', sans-serif;margin: 10px 0 30px 0;}
.infrastructre-info h5{font-size: 22px;font-weight: 700;}
.infrastructre-info ul li{font-size: 18px;padding-bottom: 10px;font-weight: 600;}
.infrastructre .row .col-6:last-child{text-align: right;}
.infrastructre .row .col-6 img{width: 90%;}
.infrastructre .col-md-5{text-align: right;}
.infrastructre img{width: 90%;}

/*------===============================-testimonials--=========================-----------------*/
.testimonials{background-color:#EEE1C7;padding: 60px 0 0px 0;}
.cards{padding:30px;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);width: 30%;border-radius: 0 !important;background-color: #fff;}
.card-titles{margin: 20px 0;font-size: 20px;font-weight: 700;}
.cards p{font-size: 18px;font-weight: 400;font-family: 'Roboto', sans-serif;color: #555050;}
.cards h6{font-size: 18px;font-weight:600;margin-bottom: 0px;margin-top: 10px;}
/* .card-img-top{width: 50% !important;} */
.card-bodys{padding: 0 !important;}
.cards .d-flex img{margin-right: 20px;height:80px;width: 80px;}
.cards .d-flex{margin: 50px 0 0 0;}
.testimonials .carousel-inner{padding-bottom: 200px;}
.testimonials .carousel-control-prev-icon{background-image:url("./imgs/left-logo.png");filter: none !important;opacity: 1;width: 20px;height: 20px;}
.testimonials .carousel-control-next-icon{background-image:url("./imgs/right-logo.png");filter: none !important;opacity: 1;width: 20px;height: 20px;}
.testimonials  .carousel-control-next,.testimonials  .carousel-control-prev{opacity: 1;top: auto;background-color: #fff;
height: 50px; bottom: 100px;width: 50px;padding: 10px;border-radius: 50%;}
.textimonial-slider{margin-top: 70px;}
.testimonials .carousel-control-prev{left: 45%;}
.testimonials .carousel-control-next{right: 45%;}
/*--------======================ABout us---======================================------*/
.about-us{padding:70px 0 50px 0;background-color: #04336A;color: #fff;border-top: 4px solid #BBB8B8;}
.our-products  .main-head{color: #fff;}
.about-us .main-head{color: #fff;}
.about-us .main-head::before{border-bottom: 3px solid #fff;}
.about-us .row{margin-top: 60px;}
.about-info{padding-left: 8%;}
.about-us .about-info h3{font-size: 40px;}
.about-us .about-info h5{font-size: 25px;margin-top: 40px;}
.about-us .about-info p{font-size: 16px;padding-top: 10px;letter-spacing: 0.16px;font-weight: 400;color: #F0F0F0;line-height: 32px; font-family: 'Inter', sans-serif;}
/*-------=========================Team----=========================--------*/
.our-team{padding: 70px 0;background-color: #E8E6DB;border-top: 4px solid #BBB8B8;}
.our-team img{width: 100%;}
.our-team .row{margin-top: 70px;}
.team-info{padding-top: 20px;}
.team-info h4{font-size: 24px;font-weight: 700;line-height: 20px;}
.team-info p{font-size: 14px;color: #222222;}
.our-products .row{margin-top: 70px;}
.our-team .main-head::before{width: 200px;}
/*-------=========================Contact Us----=========================--------*/
.contact-us{padding: 60px 0 120px 0;background-color: #04336A;color: #fff;border-top: 4px solid #BBB8B8;border-bottom: 4px solid #2C2B2B;}
.contact-us .text-center{margin-bottom: 80px;}
.contact-us .main-head{color: #fff;}
.contact-us .main-head::before{border-bottom: 3px solid #fff;}
.cntct-part{background-color: #fff;border-radius: 20px;padding: 50px 30px;}
.cntct-part h3{font-size: 40px;color:#DB2C1D;font-weight: 700;}
.cntct-part a{color: #182237;font-size: 24px;font-weight: 500;}
.contct-outer{width: 80%;margin-left: auto !important;margin-right: auto !important;}
.for-map{background-color: #fff;border-radius: 10px;padding: 40px;margin-top: 50px !important;}
.for-map iframe{height: 700px;border-radius: 10px;}
.contct-form h3{font-size: 40px;font-weight: 600;color: #000;}
.contct-form{padding-left: 7%;padding-right: 30px;}
.contct-form form{margin-top: 50px;}
.contct-form textarea{height: auto !important;min-height: 340px !important;}
.sbt-btn{border-radius: 4px;background-color: #04336A;color: #fff;width: 100%;border: 1px solid #04336A;
padding: 12px 0 !important;font-size: 20px;font-weight: 700;}
.contct-form input::placeholder{color: #BAB5B5;font-size: 18px;}
.contct-form input{padding:20px 10px;}
.contct-form textarea::placeholder{color: #BAB5B5;font-size: 18px;}
/*-----=========================================Footer-====================================---------*/
footer{background-color: #101010;padding: 90px 0 50px 0;}
.main-footer{width: 70%;color: #fff;margin: auto;}
.main-footer ul{list-style: none;padding-left: 0;}
.main-footer ul li{margin-bottom: 20px;color: #C0C0D5;font-size: 16px;}
.main-footer h3{margin-bottom: 30px;font-size: 22px;font-weight: 700;}
.main-footer p{color: #F0F0F0;}
.qck-lnks{padding-left: 50px;}
.main-footer ul li a{color: #C0C0D5;padding-bottom: 5px;}
.plays-app img{margin-bottom: 10px;}
.gettouch ul li{margin-bottom: 30px;}
.main-footer hr{margin: 70px auto 30px auto;}
.cpy-right.d-flex{justify-content: space-between;color: #C0C0D5;}
.cpy-right p{color: #C0C0D5;}
.cpy-right div a{color: #C0C0D5;font-size: 16px;}
.dot{position: relative;margin:0 10px;}
.dot::before{content: '.';position: absolute;left: 0;top:-10px;font-size: 20px;font-weight: 600;}
.main-footer ul li a{position: relative;}
.main-footer ul li a::after{content: '';position: absolute;bottom: 0;left: 0;width: 130px;border-bottom: 1px solid #C0C0D5;}
.main-footer ul li:first-child a::after{border: none;}
.about-us img{width: 100%;}
/*-------========Media Query-=====================--------------*/
@media screen and (min-width: 1600px) {
.container{ max-width: 1550px !important;}

}

@media screen and (max-width: 1500px) {
.cntct-part {padding: 50px;}
.nav-btns a {padding: 15px 20px;}
}

@media screen and (max-width: 1440px){
.nav-btns a{padding: 15px 20px;}
.inner-clnt-part {padding-left: 17%;}
}

@media screen and (max-width: 1370px) {
.navbar-brand img {width: 140px;}
.nav-btns a{padding: 15px 17px;font-size: 14px;}
.banner-info h1 {font-size: 62px;}
.banner-btn {margin-top: 50px;}
.contct-outer {width: 90%;}
.inner-clnt-part h2 {font-size: 55px;}
.inner-clnt-part p {font-size: 26px;}
.inner-clnt-part { padding-left: 25%;}
.infrastructre-info p {font-size: 16px;line-height: 27px;}
.infrastructre-info h5 {font-size: 18px;}
.infrastructre-info ul li {font-size: 16px;}
.card p {font-size: 16px;}
.about-us .about-info h3 {font-size: 35px;}
.about-us .about-info p {line-height: 26px;font-size: 15px;}
}
@media screen and (max-width: 1200px) {
.navbar-nav .nav-link {font-size: 15px;margin: 0 8px;}
.nav-btns a { padding: 10px 9px; font-size: 13px;}    
.inner-clnt-part h2 {font-size: 45px;}
.inner-clnt-part p {font-size: 22px;}
.main-head {font-size: 32px;}
.infrastructre h2 {margin-bottom: 30px;}
.infrastructre-info p {font-size: 14px;line-height: 25px;}
.infrastructre-info h5 {font-size: 15px;}
.infrastructre-info ul li { font-size: 15px;}
.textimonial-slider {margin-top: 50px;}
.card p {font-size: 14px;}
.testimonials .carousel-control-prev {left: 42%;}
.about-us .about-info h3 {font-size: 32px;}
.about-us .about-info p {line-height: 24px;font-size: 14px;}
.our-team .row {margin-top: 50px;}
.team-info h4 {font-size: 20px;}
.contact-us .text-center { margin-bottom: 50px;}
.cntct-part h3 {font-size: 32px;}
.cntct-part a {font-size: 18px;}
.for-map iframe {height: 500px;}
.contct-form input {padding: 10px;}
.contct-form textarea {min-height: 200px !important;}
.sbt-btn{font-size: 17px;}
.main-footer {width: 90%;}
.gettouch ul li {margin-bottom: 20px;}
}
@media screen and (max-width: 900px) {
.navbar-toggler{background: #fff !important;}
.navbar-nav{text-align: left;}
.nav-btns{margin: 30px 0 50px 0;}
.banner-info h1{font-size: 42px;    line-height: 70px;}
.banner-info{padding-top: 25%;}
.main-banner{height: 700px;}
.inner-clnt-part { padding-left: 20%;}
.inner-clnt-part h2{font-size: 35px;}
.inner-clnt-part p {font-size: 15px;}
.our-products {margin-top: -3px;}
.textimonial-slider {margin-top: 30px;}
.about-us img{width: 70%;}
.about-us .col-lg-5{text-align: center;margin-bottom: 20px;}
.about-info {padding-left: 4%;padding-right: 4%;text-align: justify;}
.about-us .row {margin-top: 40px;}
.contct-outer {width: auto;}
.cntct-part h3 {font-size: 24px;}
.cntct-part a {font-size: 15px;}
.cntct-part {padding:40px;}
.contct-form{padding-left: 0;padding-top: 30px;padding-right: 0;}
.for-map{padding: 30px;}
.contact-us {padding: 60px 0 70px 0;}
.for-map iframe{height: 250px;}
.footer-icons{margin-bottom: 30px;}
.card .d-flex{flex-direction: column;}
.testimonials .carousel-inner {padding-bottom: 150px;}
.testimonials .carousel-control-next{right: 40%;}
.testimonials .carousel-control-prev,.testimonials .carousel-control-next{bottom: 80px;}
.navbar-nav{margin-top: 20px;}
.our-products{padding: 0px 0 50px 0;}
.our-products .row {margin-top: 50px;}
}

@media screen and (max-width:600px) {
.navbar-brand img {width: 100px;}
.banner-info h1 {font-size: 35px;line-height: 55px;}
.banner-info {padding-top: 35%;}
.main-banner {height: 550px;}
.banner-btn { margin-top: 30px;}
.our-clients .row{justify-content: center;}
.inner-clnt-part{margin-top: 15px;margin-bottom: 15px;}
/* .our-clients{padding-bottom: 30px;} */
.our-clients{padding:30px 0 10px 0;}
.our-products .row {margin-top: 30px;}
.infrastructre {padding: 30px 0;}
.infrastructre .col-md-5 {text-align: center;}
.testimonials{display: none;}
.about-us {padding: 30px 0 30px 0;}
.team-info {padding-top: 10px;padding-bottom: 20px;}
.team-info h4 {font-size: 18px;}
.our-team {padding: 30px 0;}
.our-products{padding: 30px 0 30px 0;}
.contact-us .text-center {margin-bottom: 30px;}
.contact-us {padding: 40px 0 50px 0;}
.row.contct-outer{justify-content: center;}
.cntct-part{margin-bottom: 20px;}
.contct-form h3 {font-size: 32px;}
.contct-form form {margin-top: 20px;}
.for-map {padding: 20px;}
.qck-lnks {padding-left: 0;}
footer {    padding: 30px 0 30px 0;}
.main-footer hr {margin: 20px auto 20px auto;}
.plays-app{display: flex;}
.cpy-right.d-flex{    flex-direction: column;text-align: center;}
.cpy-right p{margin-bottom: 2px;}
.main-footer h3 {margin-bottom: 20px;font-size: 18px;}
.main-footer ul li {font-size: 14px;margin-bottom: 10px;}
.plays-app img {margin-bottom: 0;width: 95%;}
.our-clients .row{top: 0;}
.our-clients .row .col-md-4{padding-left:10px;padding-right: 10px;}
}

.team1border{
  border-bottom: 4px solid red;
  max-height: 396px;
}

.team2border{
  border-bottom: 4px solid coral;
  max-height: 396px;
}

.team3border{
  border-bottom: 4px solid #04336A;
  max-height: 396px;
}

.team4border{
  border-bottom: 4px solid royalblue;
  max-height: 396px;
}

.ourproductborder{
  border: 2px solid #fff;
  border-radius: 5px;
  max-height: 396px;
}