.mainicon {
  width: 500px;
  float: left;
  margin-left: 80px;
  height: 60px;
}

.dashicon {
  width: 150px;
  float: left;
  height: 60px;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  margin-top: 0px;
  color: white;
  border-radius: 0px;
  margin-left: 0px;
}

.dashicon:hover {
  background-color: #384EA3;
  color: white;
}

.supporticon {
  width: 150px;
  float: left;
  height: 60px;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
  margin-top: 0px;
  color: white;
  border-radius: 0px;
  margin-left: 0px;
}

.supporticon:hover {
  background-color: #384EA3;
  color: white;
}

.creative-table-container {
  padding: 20px;
}

.creative-table {
  font-size: 16px;
}

.action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  font-size: 18px;
  color: #007bff;
  /* Blue color for icons */
}

.action-button:hover {
  text-decoration: underline;
}





.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
  margin-right: 30px;
}

/* Style pagination items */
.pagination a {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

/* Style the active pagination item */
.pagination .active a {
  background-color: #007bff;
  color: #fff;
}

/* Style the "Previous" and "Next" buttons */
.pagination .previous,
/* .pagination .next {
    margin: 0 0px;
    padding: 0px 0px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  } */

/* Style the "Previous" and "Next" buttons on hover */
.pagination .previous:hover,
/* .pagination .next:hover {
    background-color: #007bff;
    color: #fff;
  } */

/* Style the ellipsis (...) */
.pagination .break {
  margin: 0 5px;
  font-weight: bold;
}

/* Style the disabled items */
.pagination .disabled a {
  pointer-events: none;
  color: #ccc;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
}



.fullbody {
  width: 100%;
  height: auto;
}

.treestyle {
  width: 15%;
  float: left;
  margin-top: 20px;
}

.centerimgpart {
  width: 85%;
  float: left;
}


.tree-view {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.tree {
  list-style-type: none;
  padding-left: 20px;
  border-left: 1px solid #333;
}

.tree li {
  margin: 5px 0;
}

.tree li::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #333;
  border-radius: 50%;
  background-color: #333;
}

.tree li:last-child::before {
  border: none;
}

/* Custom styling for different tree levels */
.tree ul {
  padding-left: 0;
  border-left: none;
}


.ordertable {
  text-align: center;
}


.fullbody {
  width: 100%;
  height: auto;
}

.imgtableorder {
  margin-top: 20px;
}




.buttondiv {
  margin-top: 20px;
}

.button {
  margin-left: 50px;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
}

.button:hover {
  background-color: darkgray;
  color: black;
  border: 1px solid;
  box-shadow: 5px 5px 0px 0px #cccc;
}



#form-file-upload {
  height: 10rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

/* .upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}
.upload-button:hover {
  text-decoration-line: underline;
} */



/* .beautiful-form {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.upload-container {
  text-align: center;
}

.upload-label {
  display: inline-block;
  border: 2px dashed #007BFF;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.drag-drop-box p {
  margin: 0;
  font-size: 16px;
}

.upload-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  float: left;
}

.upload-button:hover {
  background-color: #0056b3;
}

.file-input {
  display: none;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Add styles for hover and drag-over effects on the drop box */
.upload-label:hover {
  background-color: #d9edff;
  border: 2px dashed #0056b3;
}

.upload-label.dragover {
  background-color: #d9edff;
  border: 2px dashed #0056b3;
}

#message-box {
  height: 150px;
}

.side-menu {
  width: 250px;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  z-index: 999;
}

.side-menu.expanded {
  left: 0;
}

.menu-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.menu-header h2 {
  margin: 0;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  position: relative;
}

.menu-list a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.sub-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sub-menu li {
  padding: 10px;
  text-align: center;
}

.sub-menu a {
  text-decoration: none;
  color: #555;
  font-size: 16px;
  display: block;
}

tr:nth-child(even) {
  background-color: #DAEAE0 !important;
}

select {
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #D8E8FF;
  margin-top: 6px;
  margin-bottom: 6px;
}

.form-control {
  padding: 10px auto !important;

}

.image-view {
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: 1px solid #000;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;

}

input {
  margin-bottom: 6px;
  margin-top: 6px;
}

label {
  font-size: 14px !important;
  font-weight: 600;
  color: #555 !important;
}

.login-text {
  font-size: 25px;
  font-weight: bold;
}

.accordion-header>button {
  padding: 4px 10px;
}

.accordion-item>.accordion-header {
  margin: 1px;
}

.form-test {
  font-size: 16px;
}

.card-size {
  width: 100%;
  max-width: 250px;
  text-align: center;
  margin: 20px auto;
  margin-left: 20px;
  float: left;
  min-height: 400px;

}

.favourite-card {
  width: 13rem;
  float: left;
  margin-left: 5px;
  margin-top: 5px;
}

.top_bar li:active {
  background: rgb(235, 233, 167);
}

.box-support ul li {
  border: 1px solid rgb(235, 233, 167);
  background: white;
  padding: 30px 40px;
  width: 250px;
  height: auto;
  margin: 8px;
}

.box-lable {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}


.right-message {
  text-align: right;
  /* align-self: flex-end; */
  padding: 8px;
  background-color: #E6F2DD;
  margin-top: 5px;
  border-radius: 8;
  width: fit-content;
  align-self: flex-end;
  display: flex;
  /* align-items: self-end; */
  align-items: center;
  border-radius: 8px;
  font-size: small;
}

.left-message {
  text-align: left;
  padding: 8px;
  background-color: #e0e0e0;
  margin-top: 5px;
  border-radius: 8;
  width: fit-content;
  align-self: flex-start;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: small;

}

.chat-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.timestamp {
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
  color: #888789;
}


.box-lables {
  font-size: 16px;
  font-weight: 600;
  color: #888789;
}


.chat-container {
  border: 1px solid #ccc;
  height: 100%;
  border-radius: 5px;
  background-color: #f7f7f7;
  padding: 20px;
}

.chat {
  max-height: 60vh;
  overflow-y: auto;
}

.chat-message {
  margin: 10px 0;
}

.message-sender {
  font-weight: 600;
  margin-top: 5px;
  color: #424042;
  border-radius: 5px;
  background-color: #ffff;
  padding: 6px;
  width: fit-content;
  height: auto;
  box-shadow: 0px 1px 1px rgba(80, 80, 80, 0.3);
}

.message-text {
  margin-top: 5px;
  border-radius: 5px;
  padding: 6px;
  width: fit-content;
  height: auto;
  background-color: #D9FDD3;
  box-shadow: 0px 1px 1px rgba(80, 80, 80, 0.3);
}

#send-button {
  margin-left: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  bottom: 18;
  position: absolute;
  width: -webkit-fill-available;
  margin-bottom: 15px;
}

.custom-p {
  margin-left: 8px;
}

.chat-tittle {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #333;

}

.Extramargin {
  margin-top: 24vh;
}

.outstanding-text {
  font-size: 24px;
  font-weight: 600;
}

.login-bg {
  background-image: url('../../../assets/images/loginImg.jpg');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-color {
  background-color: #053d7d;
  color: #fff;
}

.custom-color:hover {
  background-color: #04336A;
  color: #fff;
}

.second-color {
  color: #04336A;
}

.login-text {
  font-size: 30px;
  font-weight: 600;
  color: #04336A;
}

.login-height {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

/* .nav-link.active{color: grey !important;border-bottom: 2px solid #96989B;padding-bottom: 0;} */

.clr {
  color: #007BFF;
}