@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
body {
  font-family: "Nunito Sans", sans-serif;
}
.navbar {
  background-color: rgba(255, 255, 255, 0.4);
}
.header_bg_img {
  background-image: url("../images/Rectangle\ 3.png");
}
.header_img {
  box-shadow: 10px 8px 0 0 #000000;
  border-top-right-radius: 80px;
}
.header_content {
  background-color: rgba(255, 255, 255, 0.4);
}
.carousel-img {
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border: 2px solid #db2c1d;
}
.item {
  position: relative;
  font-family: Arial;
}
.text-block {
  position: absolute;
  bottom: 0;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.experience_section_content {
  width: 200px;
  height: 200px;
}
.border-dark {
  border-color: #000000 !important;
}
.login_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.carousel_symbol {
    font-size: 10rem;
}
.our_team {
    background-color: #f7eae6;
}
.team_card {
    background-color: transparent !important;
}
.our_team_card {
    top: 13rem;
    left: 2rem;
    width: 45px;
    height: 43px;
    border-radius: 100%;
}
.plus_icon {
    padding-top: 0.2rem;
}
@media screen and (max-width: 992px) {
  .login_container {
    width: 100% !important;
  }
  .header_content {
    top: 2rem !important;
  }
}

.loginslider1{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.loginslider2{
  border-top-left-radius: 15px; 
  border-bottom-left-radius: 15px;
}


/* Loading popup starts hear */

/* Loading popup starts hear */

.loading-spinner-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Use your desired spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}