.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  margin-top: 5px;
  top: 100%;
  left: 0;
  z-index: 2000;
  float: left;
  display: none;
  min-width: 160px;
  padding: 3px;
  list-style: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow:
    0px 0px 2px rgba(000, 000, 000, 0.5),
    inset 0 1px 1px -1px #fff,
    inset 0 -1px 1px -1px #00395d;
  -moz-box-shadow:
    0px 0px 2px rgba(000, 000, 000, 0.5),
    inset 0 1px 1px -1px #fff,
    inset 0 -1px 1px -1px #00395d;

  -webkit-box-shadow:
    0px 0px 2px rgba(000, 000, 000, 0.5),
    inset 0 1px 1px -1px #fff,
    inset 0 -1px 1px -1px #00395d;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 8px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.dropdown-menu a {
  display: block;
  padding: 3px 15px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #333333;
}

.dropdown-menu .btn_24_blue {
  margin: 5px;
  width: 96%;
}

.dropdown-menu .btn_24_blue a {
  padding: 0px 15px !important;
  line-height: 24px;
  width: 86%;
}

.dropdown-menu a:hover {
  color: #069;
}

.dropdown-menu li>a:hover,
.dropdown-menu .active>a,
.dropdown-menu .active>a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
}

/*.dropdown.open .dropdown-toggle {
  color: #ffffff;
  background: #ccc;
  background: rgba(0, 0, 0, 0.3);
}
*/
.dropdown.open .dropdown-menu {
  display: block;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}


.dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #036281;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}

.dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #036281;
  position: absolute;
  top: -6px;
  left: 10px;
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}

.dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}

.colorpicker {
  border: #aaa 1px solid;
  background: #fff;
}


.switch_bar .dropdown-menu li {
  padding: 3px;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

input {
  padding: 10px auto !;

}